import React from 'react';
import { connect } from 'react-redux'

import { _setAxios, _success, _tableLogActivity, getParamTable, _validationFormMsg } from '../../lib/Helper';
import { schemaTugasTambahanSatminkal } from './schema';
import validate from 'validate.js';
import { tugasJenisAktivitasOptions, tugasJenisTugasPembelajaranOptions, tugasJenisTugasPendidikanOptions } from '../../data/options';

import { Table, Row, Col, Divider, Popover, Button, Radio,  Space, Menu, Dropdown, Input, Tooltip, Modal, List, Tag } from 'antd';
import { PageHeader } from 'components/header';
import { ClockCircleOutlined, SaveOutlined, InfoCircleOutlined, SearchOutlined, EllipsisOutlined, PlusOutlined, SortAscendingOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';

const tableOptions = {
    sorts: [{
        value: "ASC",
        label: "Ascending",
    }, {
        value: "DESC",
        label: "Descending",
    }],
    fields: [{
        value: "id",
        label: "Tanggal Pembuatan",
    }, {
        value: "nama",
        label: "Nama",
    }]
}

class TugasTambahanSatminkal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},

            table: {
                loading: false,
                data: [],
                search: "",
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                },
                sorting: [],
                filtering: {
                    tipe: "Tugas Tambahan Satminkal",
                },
            },
            form: {
                visible: false,
                loading: false,
            },
            fileList: [],
            validateFile: false,

            tipe: "Tugas Tambahan Satminkal",
            ddl: {
                rombel: [],
                jenisTugas: [],
            },
            errorMessage: null

        };
    }

    componentDidMount() {
        const { privilege } = this.props
        const { tahun_ajaran } = privilege

        this.fetchTable()
        if (this.props.privilege.tahun_ajaran) {
            this.getIdentitasLembagaByTahunAjaranID(tahun_ajaran?.id)
        }
        this.getRombelDDL()
    }

    fetchTable = () => {
        const { table } = this.state
        const params = getParamTable("default", table)
        this.fetch(params);
    }

    fetch = async (params = {}) => {
        this.setState({
            table: {
                ...params,
                loading: true
            }
        });
        _setAxios("tk/tugas/table/" + this.props.dataID, "POST", params).then(resp => {
            if (resp.status === true) {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                        data: resp.data.list,
                        pagination: {
                            ...params.pagination,
                            total: resp.data.info.total
                        }
                    }
                });
            } else {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                    }
                })
            }
        })
    };

    getIdentitasLembagaByTahunAjaranID = (id) => {
        this.setState({
            form: {
                ...this.state.form,
                loading: true
            }
        })
        _setAxios("lembaga/identitas/semester/" + id, "GET").then(resp => {
            if (resp.status === true) {
                const data = resp.data.data
                if (data.nama) {
                    this.setState({
                        values: {
                            nama_sekolah: data.nama,
                            npsn: data.npsn,
                        }
                    })
                } else {
                    this.setState({
                        errorMessage: "Mohon maaf, Anda belum bisa menambahkan data tugas utama, dikarenakan identitas lembaga di tahun ajaran yang aktif belum di update",
                    })
                }
            }
            this.setState({
                form: {
                    ...this.state.form,
                    loading: false
                }
            })
        })
    }

    getRombelDDL = () => {
        _setAxios("rombel/dropdown", "POST", {
            "pagination": {
                "current": 1,
                "pageSize": 10
            },
            "search": "",
            "sorting": []
        }).then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        rombel: resp.data.list
                    },
                })
            }
        })
    }

    handleChange = (e) => {
        const { values } = this.state;
        const { name, value } = e.target;

        this.setForm(values, name, value)
    };

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)

        if (name === "jenis_aktivitas") {
            let jenisTugas = []
            if (value === "Pembelajaran") {
                jenisTugas = tugasJenisTugasPembelajaranOptions
            } else if (value === "Kependidikan") {
                jenisTugas = tugasJenisTugasPendidikanOptions
            }

            this.setState({
                ddl: {
                    ...this.state.ddl,
                    jenisTugas: jenisTugas
                },
            });
        }
    };

    setForm = (values, name, value) => {
        values[name] = value;

        const errors = validate(values, schemaTugasTambahanSatminkal);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return this.setValidate(values)
    }

    setValidate = (values) => {
        const errors = validate(values, schemaTugasTambahanSatminkal);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return errors
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    // CREATE START ------------------------------------------
    hideModalForm = (row) => {
        this.setState({
            form: {
                ...this.state.form,
                visible: false
            },
            values: {
                nama_sekolah: this.state.values.nama_sekolah ? this.state.values.nama_sekolah : null,
                npsn: this.state.values.npsn ? this.state.values.npsn : null,
            },
            errors: {}
        });
    };

    showModalForm = (row) => {
        if (row.id) {
            this.setState({
                form: {
                    ...this.state.form,
                    visible: true,
                },

                values: {
                    id: row.id ? row.id : null,
                    tipe: this.state.tipe,
                    rombel_id: row.rombel_id ? {
                        id: row.rombel_id,
                        nama: row.rombel_nama
                    } : null,
                    // mata_pelajaran_id: row.mata_pelajaran_id ? row.mata_pelajaran_id : null,
                    nama_sekolah: row.nama_sekolah ? row.nama_sekolah : null,
                    npsn: row.npsn ? row.npsn : null,
                    jenis_aktivitas: row.jenis_aktivitas ? row.jenis_aktivitas : null,
                    jenis_tugas: row.jenis_tugas ? row.jenis_tugas : null,
                    jenjang_pendidikan: row.jenjang_pendidikan ? row.jenjang_pendidikan : null,
                    jml_siswa: row.jml_siswa ? row.jml_siswa : null,
                    jtm: row.total_jtm ? row.total_jtm : null,
                }
            });
        } else {
            this.setState({
                form: {
                    ...this.state.form,
                    visible: true,
                },
            });
        }
    };

    onSubmitForm = async (e) => {
        e.preventDefault();
        const { values } = this.state
        const { privilege } = this.props
        const { tahun_ajaran } = privilege

        //Validation 
        let validation = this.setValidate(values, schemaTugasTambahanSatminkal)
        if (validation !== undefined) {
            return _validationFormMsg(validation)
        }

        // Payload
        const param = {
            tipe: this.state.tipe,
            rombel_id: values.rombel_id ? values.rombel_id.id : null,
            mata_pelajaran_id: values.mata_pelajaran_id ? values.mata_pelajaran_id.id : null,
            tahun_ajaran_id: tahun_ajaran?.id,
            nama_sekolah: values.nama_sekolah ? values.nama_sekolah : null,
            npsn: values.npsn ? values.npsn : null,
            jenis_aktivitas: values.jenis_aktivitas ? values.jenis_aktivitas : null,
            jenis_tugas: values.jenis_tugas ? values.jenis_tugas : null,
            jenjang_pendidikan: values.jenjang_pendidikan ? values.jenjang_pendidikan : null,
            jml_siswa: values.jml_siswa ? values.jml_siswa : null,
            jtm: values.jtm ? values.jtm : null,
        }

        // Define Network
        let method = "POST"
        let payload = [param]
        let id = this.props.dataID
        if (values.id) {
            method = "PUT"
            payload = param
            id = values.id
        }

        this.setState({
            form: {
                ...this.state.form,
                loading: true
            }
        });
        _setAxios("tk/tugas-utama/" + id, method, payload).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.hideModalForm()
                this.fetchTable()
            }

            this.setState({
                form: {
                    ...this.state.form,
                    loading: false
                }
            });
        })
    };

    // CREATE END ------------------------------------------


    onDelete = (row) => {
        Modal.confirm({
            title: 'Hapus Data',
            width: 470,
            icon: <ExclamationCircleOutlined />,
            content: <>Data akan dihapus dari list, Apakah Anda Yakin ?</>,
            cancelText: 'Batal',
            okText: 'Simpan',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    _setAxios("tk/tugas/" + row.id, "DELETE").then(resp => {
                        if (resp.status === true) {
                            _success('topRight', 'Success', resp.data.message)
                            this.fetchTable()
                        }
                        setTimeout(Math.random() > 0.5 ? resolve : reject, 100);
                    })
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel: () => {
                console.log("No action to process")
            },
        });
    }

    render() {
        const { table, ddl, values, errors } = this.state;
        const { privilege } = this.props
        const tahunAjaran = privilege.tahun_ajaran ? privilege.tahun_ajaran?.tahun_ajaran + " - " + privilege.semester?.tipe_semester : null

        const sortComponent = <>
            <Radio.Group onChange={(e) => {
                const params = getParamTable("sort_field", table, e.target.value)
                this.fetch(params)
            }} value={table.sorting.length > 0 ? table.sorting[0].field : ""} name="sort_field">
                <Space direction="vertical">
                    {tableOptions.fields.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
                </Space>
            </Radio.Group>
            <Divider orientation="left"></Divider>
            <Radio.Group onChange={(e) => {
                const params = getParamTable("sort", table, e.target.value)
                this.fetch(params)
            }} value={table.sorting.length > 0 ? table.sorting[0].sort : ""} name="sort_value">
                <Space direction="vertical">
                    {tableOptions.sorts.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
                </Space>
            </Radio.Group>
        </>

        const modalForm = <Modal
            title="Form Tugas Tambahan Satminkal"
            visible={this.state.form.visible}
            // width={700}
            onCancel={this.hideModalForm}
            footer={<>
                <Button onClick={this.hideModalForm}>Batal</Button>
                <Button type="primary" htmlType="submit" onClick={this.onSubmitForm} loading={this.state.form.loading} icon={<SaveOutlined />}>Simpan</Button>
            </>}
        >
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <TextField
                        fullWidth
                        label="Tahun Ajaran"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="tahun_ajaran_id"
                        type="text"
                        onChange={this.handleChange}
                        value={tahunAjaran || ""}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Col>
                <Col xs={24}>
                    <TextField
                        fullWidth
                        label="Nama Sekolah"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="nama_sekolah"
                        type="text"
                        onChange={this.handleChange}
                        value={values.nama_sekolah || ''}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Col>
                <Col xs={24}>
                    <TextField
                        fullWidth
                        label="NPSN"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="npsn"
                        type="text"
                        onChange={this.handleChange}
                        value={values.npsn || ''}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Col>
                <Col xs={24} sm={12}>
                    <Autocomplete
                        options={tugasJenisAktivitasOptions}
                        renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Jenis Aktivitas"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="jenis_aktivitas"
                            type="text"
                            error={this.hasError('jenis_aktivitas')}
                            helperText={
                                this.hasError('jenis_aktivitas') ? errors.jenis_aktivitas[0] : null
                            }
                        />}
                        value={values.jenis_aktivitas || null}
                        onChange={(e, newValue) => {
                            this.handleChangeSetValue("jenis_aktivitas", newValue ? newValue.value : null)
                            this.handleChangeSetValue("jenis_tugas", null)
                        }}
                    />
                </Col>
                <Col xs={24} sm={12}>
                    <Autocomplete
                        options={ddl.jenisTugas}
                        renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Jenis Tugas"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="jenis_tugas"
                            type="text"
                            error={this.hasError('jenis_tugas')}
                            helperText={
                                this.hasError('jenis_tugas') ? errors.jenis_tugas[0] : null
                            }
                        />}
                        value={values.jenis_tugas || null}
                        onChange={(e, newValue) => {
                            this.handleChangeSetValue("jenis_tugas", newValue ? newValue.value : null)

                            if (newValue && newValue.value === "Guru ubudiyah") {
                                this.handleChangeSetValue("jtm", "0")
                            }
                        }}
                    />
                </Col>
                {
                    values.jenis_aktivitas === "Pembelajaran" ?
                        <>
                            <Col xs={24} sm={12}>
                                <Autocomplete
                                    options={ddl.rombel}
                                    renderInput={(params) => <TextField {...params}
                                        fullWidth
                                        label="Rombel *"
                                        size="small"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        name="rombel_id"
                                        type="text"
                                        helperText={
                                            this.hasError('rombel_id') ? errors.rombel_id[0] : null
                                        }
                                    />}
                                    value={values.rombel_id || null}
                                    getOptionLabel={(option) =>
                                        option.nama
                                    }
                                    onChange={(e, newValue) => {
                                        this.handleChangeSetValue("rombel_id", newValue ? newValue : null)
                                    }}

                                />
                            </Col>
                            <Col xs={12}>
                                <TextField
                                    fullWidth
                                    label="Jumlah Siswa *"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="jml_siswa"
                                    type="text"
                                    onChange={this.handleChange}
                                    value={values.jml_siswa || ''}
                                    error={this.hasError('jml_siswa')}
                                    helperText={
                                        this.hasError('jml_siswa') ? errors.jml_siswa[0] : null
                                    }
                                />
                            </Col>
                        </>
                        : ""
                }
                <Col xs={12}>
                    <TextField
                        fullWidth
                        label="JTM"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="jtm"
                        type="text"
                        onChange={this.handleChange}
                        value={values.jtm || ''}
                        error={this.hasError('jtm')}
                        helperText={
                            this.hasError('jtm') ? errors.jtm[0] : null
                        }
                        InputProps={{
                            endAdornment: <InputAdornment position="end"><ClockCircleOutlined /></InputAdornment>,
                            min: 0, max: 100,
                            readOnly: values.jenis_tugas === "Guru ubudiyah" ? true : false
                        }}
                    />
                </Col>
            </Row>
        </Modal>

        return (
            <>
                <PageHeader
                    className="site-page-header"
                    title="Tugas Tambahan Satminkal"
                    extra={
                        <>
                            <Tooltip title="Tambah">
                                <Button type='primary' block icon={<PlusOutlined />} onClick={this.showModalForm}> Tambah</Button>
                            </Tooltip>
                        </>
                    }

                />
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={21}>
                        <Input
                            name="search"
                            className='search-table'
                            placeholder="Search..."
                            prefix={<SearchOutlined className="site-form-item-icon" />}
                            onChange={(e) => {
                                const params = getParamTable("search", table, e.target.value)
                                this.fetch(params)
                            }}
                            value={table.search || ""}
                            suffix={
                                <Tooltip title="Cari berdasarkan nama">
                                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                </Tooltip>
                            }
                        />
                    </Col>
                    <Col xs={24} sm={3}>
                        <Button.Group className='ant-btn-group-fullwidth'>
                            <Popover placement="bottom" content={sortComponent} trigger="click">
                                <Button block className='btn-border-radius-right'><SortAscendingOutlined /> Sort</Button>
                            </Popover>
                        </Button.Group>
                    </Col>

                </Row>

                <Divider orientation="left"></Divider>

                <Row>
                    <Col span={24}>
                        <Table
                            columns={[
                                {
                                    title: 'No',
                                    dataIndex: 'rownum',
                                    width: '3%',
                                },
                                {
                                    title: 'Tahun Ajaran',
                                    dataIndex: 'tahun_ajaran_id',
                                    width: '20%',
                                    render: (a, row) => <List.Item style={{ padding: 0 }}>
                                        <List.Item.Meta
                                            title={<>{row.thn_awal}/{row.thn_akhir}</>}
                                            description={<Tag color={row.tipe_semester === "Genap" ? "blue" : "green"}> {row.tipe_semester}</Tag>}
                                        />
                                    </List.Item>,
                                },
                                {
                                    title: 'Nama Sekolah',
                                    dataIndex: 'nama_sekolah',
                                },
                                {
                                    title: 'Tugas Tambahan Satminkal',
                                    dataIndex: 'jenis_tugas',
                                },
                                {
                                    title: 'Mata Pelajaran',
                                    dataIndex: 'mata_pelajaran_nama',
                                },
                                {
                                    title: 'Rombel',
                                    dataIndex: 'rombel_nama',
                                },
                                {
                                    title: 'Jumlah Siswa',
                                    dataIndex: 'jml_siswa',
                                },
                                {
                                    title: 'JTM',
                                    dataIndex: 'total_jtm',
                                },
                                {
                                    title: <EllipsisOutlined />,
                                    align: "center",
                                    key: 'operation',
                                    width: '2%',
                                    render: (a, row) => <Dropdown trigger={['click']} overlay={<Menu>
                                        {row.jenis_tugas !== "Guru mapel" ? <Menu.Item key={0} onClick={this.showModalForm.bind(this, row)}><EditOutlined /> Update</Menu.Item> : ""}
                                        <Menu.Item key={1} onClick={this.onDelete.bind(this, row)}><DeleteOutlined /> Delete</Menu.Item>
                                    </Menu>}>
                                        <div className="ant-dropdown-link tb-action">
                                            <Button type='primary'>Opsi</Button>
                                        </div>
                                    </Dropdown>,
                                },
                                {
                                    title: 'LogActivity',
                                    key: 'operation',
                                    width: '10%',
                                    render: (a, row) => _tableLogActivity(row),
                                },
                            ]}
                            rowKey={record => record.id}
                            dataSource={table.data}
                            pagination={table.pagination}
                            loading={table.loading}
                            onChange={(pagination) => {
                                const params = getParamTable("change", table, null, null, pagination)
                                this.fetch(params)
                            }}
                            size="small"
                        />
                    </Col>
                </Row>

                {modalForm}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(TugasTambahanSatminkal);
